import {useSelector} from "react-redux";
import {selectIsLoading} from "../store/site-slice";

export default function Spinner() {

    const isLoading = useSelector(selectIsLoading)

    return <>{isLoading && <dialog className="modal modal-open">
        <div className="modal-box bg-black opacity-75">
            <div className={"flex gap-x-2 place-content-center items-center"}>
                <div>
                <span className="text-white loading loading-ring loading-lg"></span>
                <span className="text-white loading loading-ring loading-lg"></span>
                <span className="text-white loading loading-ring loading-lg"></span>
                <span className="text-white loading loading-ring loading-lg"></span>
                </div>
            </div>
        </div>
    </dialog>}</>
}